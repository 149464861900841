.header-enter {
  transform: translateY(-100%);
}
.header-enter-active {
  transform: translateY(0%);
  transition: all 0.3s cubic-bezier(0, 1, 0.5, 1);
}
.header-exit {
  transform: translateY(0%);
}
.header-exit-active {
  transform: translateY(-100%);
  transition: all 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.title-enter {
  opacity: 0;
}
.title-enter-active {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0, 1, 0.5, 1);
}
.title-exit {
  opacity: 1;
}
.title-exit-active {
  opacity: 0.01;
  transition: all 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.opacity-enter {
  opacity: 0.01;
}
.opacity-enter-active {
  opacity: 1;
  transition: all 2s cubic-bezier(0, 1, 0.5, 1);
}
.opacity-exit {
  opacity: 1;
}
.opacity-exit-active {
  opacity: 0.01;
  transition: all 2s cubic-bezier(0, 1, 0.5, 1);
}